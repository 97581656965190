import * as React from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect-1.svg";
import TunisiaJobs from "../assets/img/tunisia-jobs-5-12.png";
import Impact from "../assets/img/demographic.png";
import Impact2 from "../assets/img/internal-travel.png";
const OurImpact = () => (
  <Layout>
    <SEO title="Our Impact | Visit Tunisia Project" />
    <main role="main">
      <div className="container-fluid">
        <div className="region region-banner">
          <div
            id="block-impact"
            className="block block-block-content block-block-content06f829d4-3b45-4a4f-873c-10cef31cf5a5"
          >
            <div className="intern-banner">
              <div className="overlay">
                <img src={BannerEffect} alt="" />
              </div>
              <img src={TunisiaJobs} alt="" />
              <div className="caption">
                <h1 className="animate__animated animate__fadeInUp animate__slow">
                  <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                    Our Impact
                  </div>
                </h1>
                <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="region region-content">
          <div data-drupal-messages-fallback className="hidden" />
          <div
            id="block-jobs-front-content"
            className="block block-system block-system-main-block"
          >
            <article
              data-history-node-id={25}
              role="article"
              about="/en/our-impact"
              typeof="schema:WebPage"
              className="node node--type-page node--view-mode-full"
            >
              <span
                property="schema:name"
                content="Our Impact"
                className="rdf-meta hidden"
              />
              <div className="node__content">
                <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                  <div>
                    <section className="our-impact">
                      <div className="container">
                        <p>
                          USAID Visit Tunisia conducted a nationwide survey on
                          domestic tourism that provided critical insight into
                          Tunisian tourists’ demographics, travel preferences,
                          and spending habits to inform the country’s tourism
                          strategy and marketing campaigns. The survey results
                          collected from 1,200 Tunisians from all 24
                          governorates provided a comprehensive overview of
                          domestic tourists’ profiles in the country. In
                          addition, the survey also identified the major
                          obstacles preventing non travelers from visiting
                          regions other than their home cities and provided
                          recommendations to overcome those obstacles.
                        </p>
                        <p>&nbsp;</p>
                        <div className="impacts-details d-flex flex-md-row flex-column flex-wrap">
                          <div
                            className="item work-card aos-animate"
                            style={{ position: "relative" }}
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                            <img src={Impact} alt="" />
                            <div className="card-text">
                              <div
                                className="card-info justify-content-between align-items-center"
                                style={{ margin: "25px" }}
                              >
                                <span>
                                  Its results are helping the project and its
                                  government partners, Tunisia’s Ministry of
                                  Tourism and Handicrafts (MoTH) and the
                                  National Tourism Office (ONTT), craft an
                                  effective national tourism strategy and
                                  marketing campaign that prioritizes
                                  sustainable tourism projects in underserved
                                  regions.{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="item work-card aos-animate"
                            style={{ position: "relative", marginTop: "20px" }}
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                            <img src={Impact2} alt="" />
                            <div className="card-text">
                              <div
                                className="card-info justify-content-between align-items-center"
                                style={{ margin: "25px" }}
                              >
                                <span>
                                  Are you a Tunisian company, tour agency,
                                  content creator, or X interested in joining
                                  our Domestic Tourism Campaign? Partner with us{" "}
                                  <a href="/partner-with-us">here.</a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </main>
  </Layout>
);

export default OurImpact;
